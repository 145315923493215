import React, { useState, useEffect } from 'react';
import Classnames from 'classnames';
import { ClickableCard } from '../cards';

import './copy-content.scss';

interface CopyContentProps {
    content: string;
    title: string;
    shouldUseNavigatorApi?: boolean;
    // To enable the navigator.clipboard API within an iframe, ensure the iframe includes
    // the attribute: allow='clipboard-read; clipboard-write'. This grants necessary permissions
    // for clipboard operations (reading and writing).
}

export const CopyContent: React.FC<CopyContentProps> = ({
    content,
    title,
    shouldUseNavigatorApi = false,
}): React.ReactElement => {
    const [contentCopied, setContentCopied] = useState(false);
    const classes = Classnames('copy-container', { 'copy-success': contentCopied });

    let copiedTimer: ReturnType<typeof setTimeout>;

    const checkContentCopiedTimer = () => {
        if (copiedTimer) {
            clearTimeout(copiedTimer);
        }
    };

    useEffect(() => {
        return () => {
            checkContentCopiedTimer();
        };
    }, []);

    const copyContent = async () => {
        checkContentCopiedTimer();

        if (shouldUseNavigatorApi) {
            try {
                await navigator.clipboard.writeText(content);
                setContentCopied(true);
            } catch (err) {
                console.error('Failed to copy content: ', err);
            }
        } else {
            const elem = document.createElement('textarea');
            elem.value = content;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
            setContentCopied(true);
        }
        copiedTimer = setTimeout(() => {
            setContentCopied(false);
        }, 2500);
    };

    return (
        <div className={classes} data-testid="copy-container">
            <div className="copy-label-container">
                <p className="copy-label">
                    <b>{title}</b>
                </p>
                <p className="copied" data-testid="copied-text">
                    Copied
                </p>
            </div>
            <ClickableCard className="copy-value-container" onClick={copyContent} cardName={'copy-content'}>
                <p className="copy-value">{content}</p>
                <span>
                    <i className="fa-kit fa-prizeout-copy" title="copy" data-testid="copy-icon"></i>
                </span>
            </ClickableCard>
        </div>
    );
};
