import React, { ForwardRefExoticComponent, forwardRef, useMemo } from 'react';
import ClassNames from 'classnames';
import { InputComponentProps } from './validated-input';
import { useRefAssigner } from '../../../hooks/use-ref-assigner';
import { FieldErrors } from 'react-hook-form';
import { MonetaryInputProps } from './monetary-input';
import { AlertCallout, AlertCalloutMessageTypes } from '../ui-widgets';
import { getMessageFromError } from '../../../utils';
import { ParagraphSecondary } from '../typography';

import './input-wrapper.scss';

export interface InputWrapperProps {
    inputId: string;
    labelText: string;
    useErrorAsLabel?: boolean;
    showErrorCallout?: boolean;
    InputComponent?: ForwardRefExoticComponent<any>;
    inputProps?: InputComponentProps | MonetaryInputProps;
    formErrors?: FieldErrors;
    errorName?: string;
    caption?: string;
}

export const InputWrapper = forwardRef(function (
    {
        inputId,
        labelText,
        useErrorAsLabel = false,
        showErrorCallout = false, // If we eliminate `useErrorAsLabel` we should default this to `true`
        InputComponent,
        inputProps,
        formErrors,
        caption,
    }: InputWrapperProps,
    ref: React.RefCallback<HTMLInputElement> | React.MutableRefObject<HTMLInputElement>,
): React.ReactElement {
    const refAssigner = useRefAssigner(ref);

    if (formErrors && Object.keys(formErrors).length > 0) {
        inputProps.showValidation = true;
    }

    const inputClasses = ClassNames('input-wrapper__input', {
        [inputProps.className]: inputProps.className,
    });

    const getLabelText = () => {
        if (useErrorAsLabel && formErrors && inputProps?.name && formErrors[inputProps.name]) {
            return formErrors[inputProps.name].message;
        }

        return labelText;
    };

    const showRenderErrorCallout = useMemo(() => {
        if (!showErrorCallout) {
            return false;
        }

        if (!formErrors || !inputProps?.name) {
            return false;
        }

        if (!formErrors[inputProps.name]) {
            return false;
        }

        return true;
    }, [showErrorCallout, formErrors, inputProps]);

    const renderCaptionOrError = () => {
        if (showRenderErrorCallout) {
            return (
                <AlertCallout
                    message={getMessageFromError(formErrors[inputProps.name])}
                    type={AlertCalloutMessageTypes.ERROR}
                />
            );
        }

        if (caption) {
            return <ParagraphSecondary text={caption} />;
        }

        return null;
    };

    return (
        <>
            <div className="input-wrapper">
                {InputComponent ? (
                    <InputComponent id={inputId} ref={refAssigner} {...inputProps} className={inputClasses} />
                ) : (
                    <input
                        data-testid="input-wrapper-default-input"
                        id={inputId}
                        ref={refAssigner}
                        {...inputProps}
                        className={inputClasses}
                    />
                )}
                <label
                    data-testid="input-wrapper-label"
                    className="input-wrapper__label"
                    htmlFor={inputId}
                    title={getLabelText()}
                >
                    {getLabelText()}
                </label>
            </div>
            {renderCaptionOrError()}
        </>
    );
});
