import React from 'react';
import ReactModal from 'react-modal';
import Classnames from 'classnames';
import { GenericModalLayout } from './common/generic-modal-layout';
import { ButtonColor } from '../buttons';
import { ToolTipDirectionTypes, ToolTipTriggerTypes } from '../ui-widgets';

import './generic-modal.scss';

interface GenericModalProps {
    alertImage?: string; // Requires hasAlertImage
    ariaDescribedById?: string; // This ID needs to match the id on the children's content div or paragraph tag, don't include if no supporting copy. ie. aria-${formName}-description
    ariaLabelledById?: string; // This ID needs to match the id on the children's headline. ie. aria-${formName}-headline
    ariaLabel: string; // This is a general description of the modal
    customClassName?: string;
    customOverlayClassName?: string;
    hasAlertImage?: boolean;
    hasExitButton?: boolean;
    hasIcon?: boolean; // Has icon in upper left corner of modal
    headline?: string;
    iconClasses?: string; // Requires hasIcon, defaults to prizeout-alert-circle icon
    isOpen: boolean;
    logomarkUrl?: string; // Display brand logo in upper left corner of modal
    onClose: () => void;
    portalClassName?: string; // Class name of parent wrapper
    primaryButtonText?: string;
    primaryButtonColor?: ButtonColor;
    primaryIsDisabled?: boolean;
    primaryIsLoading?: boolean;
    primaryOnClick?: () => void;
    primaryTestId?: string;
    secondaryButtonText?: string; // Requires primaryOnClick
    secondaryButtonColor?: ButtonColor;
    secondaryIsDisabled?: boolean;
    secondaryIsLoading?: boolean;
    secondaryOnClick?: () => void;
    secondaryTestId?: string;
    subheadline?: string; // Requires headline
    testId?: string;
    toolTipText?: string;
    toolTipTrigger?: ToolTipTriggerTypes;
    toolTipDirection?: ToolTipDirectionTypes;
    formId?: string;
    shouldCloseOnOverlayClick?: boolean;
}

export const GenericModal: React.FC<GenericModalProps> = ({
    alertImage,
    ariaDescribedById,
    ariaLabelledById,
    ariaLabel,
    children,
    customClassName,
    customOverlayClassName,
    hasAlertImage,
    hasExitButton,
    hasIcon,
    headline,
    iconClasses = `fak fa-prizeout-alert-circle`,
    isOpen,
    logomarkUrl,
    onClose,
    portalClassName = 'generic-modal',
    primaryButtonText,
    primaryButtonColor,
    primaryIsDisabled = false,
    primaryIsLoading,
    primaryOnClick,
    primaryTestId = 'generic-modal-primary-button',
    secondaryButtonText,
    secondaryButtonColor,
    secondaryIsDisabled,
    secondaryIsLoading,
    secondaryOnClick,
    secondaryTestId = 'generic-modal-secondary-button',
    subheadline,
    testId,
    toolTipText,
    toolTipTrigger,
    toolTipDirection,
    formId,
    shouldCloseOnOverlayClick = true,
}): React.ReactElement => {
    const defaultTestId = 'generic-modal';
    const className: string = Classnames('modal z-index-modal-full', customClassName);
    const overlayClassName: string = Classnames('overlay z-index-overlay-full', customOverlayClassName);

    return (
        <ReactModal
            aria={{
                describedby: ariaDescribedById,
                labelledby: ariaLabelledById,
            }}
            ariaHideApp={false}
            bodyOpenClassName="modal-body-open"
            className={className}
            contentLabel={ariaLabel}
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            overlayClassName={overlayClassName}
            portalClassName={portalClassName}
            testId={testId || defaultTestId}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            <GenericModalLayout
                alertImage={alertImage}
                hasAlertImage={hasAlertImage}
                ariaDescribedById={ariaDescribedById}
                ariaLabelledById={ariaLabelledById}
                hasExitButton={hasExitButton}
                hasIcon={hasIcon}
                headline={headline}
                iconClasses={iconClasses}
                logomarkUrl={logomarkUrl}
                onClose={onClose}
                primaryButtonText={primaryButtonText}
                primaryButtonColor={primaryButtonColor}
                primaryIsDisabled={primaryIsDisabled}
                primaryIsLoading={primaryIsLoading}
                primaryOnClick={primaryOnClick}
                primaryTestId={primaryTestId}
                secondaryButtonText={secondaryButtonText}
                secondaryButtonColor={secondaryButtonColor}
                secondaryIsDisabled={secondaryIsDisabled}
                secondaryIsLoading={secondaryIsLoading}
                secondaryOnClick={secondaryOnClick}
                secondaryTestId={secondaryTestId}
                subheadline={subheadline}
                toolTipText={toolTipText}
                toolTipTrigger={toolTipTrigger}
                toolTipDirection={toolTipDirection}
                formId={formId}
            >
                {children}
            </GenericModalLayout>
        </ReactModal>
    );
};
