import {
    PrizeoutOffer,
    PrizeoutOffers,
    PrizeoutOfferViews,
    PrizeoutOfferSettingsDisplayType,
    PrizeoutOfferViewsType,
} from '../../store/slices/offers-slice';

export const wrapOfferDataInView: (activeCategoryId: string, offers: PrizeoutOffer[]) => PrizeoutOffers = (
    activeCategoryId: string,
    offers: PrizeoutOffer[],
) => {
    const view: PrizeoutOfferViews = {
        data: offers,
        settings: {
            category_id: activeCategoryId,
            display_type: PrizeoutOfferSettingsDisplayType.VERTICAL_LIST_OFFER,
            should_load_offer_details: true,
        },
        type: PrizeoutOfferViewsType.VERTICAL_LIST_OFFERS,
    };

    return [view];
};
