import { PageManager, PageManagerPageType } from './page-manager';

export enum PagedListStateActionNames {
    DECREMENT_FIRST_PAGE = 'decrement-first-page',
    SET_FIRST_PAGE = 'set-first-page',
    SET_IS_EMPTY = 'set-is-empty',
    SET_IS_ERROR = 'set-is-error',
    SET_LOADING = 'set-loading',
    SET_NEEDS_PAGE = 'set-needs-page',
    UNSET_NEEDS_PAGE = 'unset-needs-page',
    UPDATE_DATALIST = 'update-dataList',
    RESET = 'reset',
}

export interface PagedListState<RowType> {
    pageManagerHash: string;
    isLoading: boolean;
    atEnd: boolean;
    needsPage: boolean;
    isEmpty: boolean;
    isError: boolean;
    isFirstPage: boolean;
    firstPageNumber: number;
    pageManager: PageManager<RowType>;
}

interface DecrementFirstPageAction<RowType> {
    type: PagedListStateActionNames.DECREMENT_FIRST_PAGE;
    newPage?: PageManagerPageType<RowType>;
}

interface SetFirstPageAction {
    type: PagedListStateActionNames.SET_FIRST_PAGE;
    firstPageNumber: number;
}

interface SetIsEmptyAction {
    type: PagedListStateActionNames.SET_IS_EMPTY;
}

interface SetIsErrorAction {
    type: PagedListStateActionNames.SET_IS_ERROR;
}

interface SetLoadingAction {
    type: PagedListStateActionNames.SET_LOADING;
}

interface SetNeedsPageAction {
    type: PagedListStateActionNames.SET_NEEDS_PAGE;
}

interface UnsetNeedsPageAction {
    type: PagedListStateActionNames.UNSET_NEEDS_PAGE;
}

interface UpdateDatalistAction<RowType> {
    type: PagedListStateActionNames.UPDATE_DATALIST;
    newPage: PageManagerPageType<RowType>;
}

interface ResetAction {
    type: PagedListStateActionNames.RESET;
}

export type PagedListStateAction<RowType> =
    | DecrementFirstPageAction<RowType>
    | SetFirstPageAction
    | SetIsEmptyAction
    | SetIsErrorAction
    | SetLoadingAction
    | SetNeedsPageAction
    | UnsetNeedsPageAction
    | UpdateDatalistAction<RowType>
    | ResetAction;

export const INITIAL_PAGED_LIST_STATE: PagedListState<any> = {
    pageManagerHash: '',
    isLoading: false,
    atEnd: false,
    needsPage: true,
    isEmpty: false,
    isError: false,
    isFirstPage: true,
    firstPageNumber: 1,
    pageManager: null,
};

export function pagedListStateReducer<RowType>(state: PagedListState<RowType>, action: PagedListStateAction<RowType>) {
    if (state.pageManager == null) {
        throw new Error('No pageManager has been provided');
    }

    switch (action.type) {
        case PagedListStateActionNames.DECREMENT_FIRST_PAGE:
            if (state.pageManager.startingPage > 1) {
                if (action.newPage) {
                    state.pageManager.addPage(action.newPage);
                }

                state.pageManager.startingPage--;

                return {
                    ...state,
                    pageManagerHash: state.pageManager.changeHash,
                    firstPageNumber: state.pageManager.startingPage,
                    isFirstPage: true,
                    atEnd: false,
                };
            }

            return state;

        case PagedListStateActionNames.SET_FIRST_PAGE:
            if (state.pageManager.startingPage !== action.firstPageNumber) {
                state.pageManager.startingPage = action.firstPageNumber;

                return {
                    ...state,
                    pageManagerHash: state.pageManager.changeHash,
                    firstPageNumber: state.pageManager.startingPage,
                    isFirstPage: true,
                    atEnd: false,
                };
            }

            return state;

        case PagedListStateActionNames.SET_IS_EMPTY:
            state.pageManager.clearAllPages();

            return {
                ...INITIAL_PAGED_LIST_STATE,
                isEmpty: true,
                atEnd: true,
                pageManager: state.pageManager,
                pageManagerHash: state.pageManager.changeHash,
            };

        case PagedListStateActionNames.SET_IS_ERROR:
            return {
                ...state,
                pageManagerHash: state.pageManager.changeHash,
                isError: true,
                atEnd: true,
                isLoading: false,
            };

        case PagedListStateActionNames.SET_LOADING:
            return {
                ...state,
                pageManagerHash: state.pageManager.changeHash,
                isLoading: true,
            };

        case PagedListStateActionNames.SET_NEEDS_PAGE:
            return {
                ...state,
                pageManagerHash: state.pageManager.changeHash,
                needsPage: true,
            };

        case PagedListStateActionNames.UNSET_NEEDS_PAGE:
            if (state.needsPage) {
                return {
                    ...state,
                    pageManagerHash: state.pageManager.changeHash,
                    needsPage: false,
                };
            }

            return state;

        case PagedListStateActionNames.RESET:
            return {
                ...INITIAL_PAGED_LIST_STATE,
                pageManager: state.pageManager,
                pageManagerHash: state.pageManager.changeHash,
            };

        case PagedListStateActionNames.UPDATE_DATALIST:
            if (action.newPage && state.pageManager.canAddPage(action.newPage)) {
                state.pageManager.addPage(action.newPage);

                return {
                    ...state,
                    pageManagerHash: state.pageManager.changeHash,
                    isLoading: false,
                    needsPage: false,
                    isFirstPage: false,
                    pageCount: state.pageManager.pageLength,
                };
            } else {
                return {
                    ...state,
                    pageManagerHash: state.pageManager.changeHash,
                    atEnd: true,
                    isLoading: false,
                    needsPage: false,
                    isFirstPage: false,
                    lastElement: null as Element,
                    pageCount: state.pageManager.pageLength,
                };
            }

        default:
            console.error(`Unknown paged list state reducer action: ${action}`);
            return state;
    }
}
