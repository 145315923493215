// Types for button components.  This is here to help avoid circular
// dependencies on import.
export enum ButtonColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    DESTRUCTIVE = 'destructive',
    SUCCESS = 'success',
}

export enum ButtonIconPosition {
    LEFT = 'left',
    TOP = 'top',
    RIGHT = 'right',
}

export enum ButtonSize {
    MINI = 'mini',
    SMALL = 'small',
    MEDIUM = 'medium',
}

export enum ButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}
