import React, { useEffect, useRef, useState } from 'react';
import Classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { isHTMLElement } from '../../../utils';
import { Button, ButtonColor, ButtonSize } from '../buttons';
import { PagedListItemProps } from './paged-list-item-wrapper';
import {
    TrackedHandlerList,
    useIntersectionObserver,
    useNumberSequence,
    useStringSequence,
    useUpdateEffect,
} from '../../../hooks';
import { PagedListStateActionNames } from './paged-list-reducer';
import { PagedListStateManager } from './use-paged-list-state';
import { PagingComponent } from './paging-component';
import { PagedListWrapper } from './paged-list-wrapper';
import { PageManager } from './page-manager';
import { PagedRow } from './page-manager/page-manager-base';

import './paged-list.scss';

const LAST_ELEMENT_TRACKING_ID = '.paged-list-item:last-child';

interface PagedList<RowType = string[]> {
    // Page retrieval
    getNextPage: () => Promise<void>;

    // Row component
    ListItemComponent: React.ComponentType<PagedListItemProps<RowType>>;

    // Custom components
    CustomLoaderComponent?: React.ComponentType;
    EmptyListComponent?: React.ComponentType;
    ErrorComponent?: React.ComponentType;
    pageSelectorComponent?: JSX.Element;

    // Callbacks
    atEnd?: () => void;
    filterList?: (sourceList: PageManager<RowType>) => PagedRow<RowType>[];
    onEmptyFilteredList?: () => boolean;
    onError?: (err: Error) => void;
    onFirstPage?: (err?: Error) => boolean;
    setCurrentPage?: (pageNumber: number) => void;

    // Config items
    dense?: boolean;
    idBase?: string;
    pageLength?: number;
    shouldShowLogo?: boolean;

    // State management
    pagingStateManager: PagedListStateManager<RowType>;
}

export enum InfiniteScrollState {
    EMPTY = 'empty',
    ERROR = 'error',
    LOADING = 'loading',
    RESOLVED = 'resolved',
}

export function PagedList<RowType>({
    // Page retrieval
    getNextPage,

    // Row component
    ListItemComponent,

    // Custom components
    CustomLoaderComponent,
    EmptyListComponent,
    ErrorComponent,
    pageSelectorComponent,

    // Callbacks
    atEnd,
    filterList = (sourceList) => sourceList.map((row) => row),
    onEmptyFilteredList,
    onError,
    onFirstPage,

    // Config items
    dense = false,
    idBase = 'pagedList',
    pagingStateManager: localStateManager,
    pageLength = 50,
    shouldShowLogo,

    // State management
    setCurrentPage,
}: PagedList<RowType>): React.ReactElement {
    const idSeq = useStringSequence(idBase);
    const [localState, dispatchLocalState] = localStateManager;
    const [showEmptyFilterResults, setShowEmptyFilterResults] = useState(false);
    const loadingKeys = useNumberSequence();

    const listClassnames = Classnames('paged-list__list', {
        'paged-list__list--dense': dense,
    });

    const skeletonClassnames = Classnames('paged-list__loading-item', {
        'paged-list__loading-item--top': localState.isLoading,
    });

    const handlerList = useRef<TrackedHandlerList>({});
    const handleObserverChange: IntersectionObserverCallback = (
        entries: IntersectionObserverEntry[],
        observer,
    ): void => {
        if (!entries) {
            return;
        }

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (isHTMLElement(entry.target)) {
                    const tracking_id = entry.target.dataset.tracking_id;
                    const handler = handlerList.current[tracking_id];

                    if (handler) {
                        handler.handler(observer, entry);
                    }
                }
            }
        });
    };

    const handleListTop = () => {
        dispatchLocalState({
            type: PagedListStateActionNames.DECREMENT_FIRST_PAGE,
        });
    };

    const topRef = useRef(null);
    const { observer, setTopElement, trackElement, trackedHandlers, resetHandlers } = useIntersectionObserver(
        topRef,
        handleObserverChange,
        {
            threshold: [0.05, 1.0],
        },
        setCurrentPage ? handleListTop : null,
    );

    const handleLastElementIntersect = (entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
            if (localState.pageManager.pagesLeftToLoad > 0) {
                dispatchLocalState({ type: PagedListStateActionNames.SET_NEEDS_PAGE });
            }

            return true;
        }

        return false;
    };

    useEffect(() => {
        setTopElement(topRef.current);
    }, []);

    useEffect(() => {
        if (observer) {
            trackElement(LAST_ELEMENT_TRACKING_ID, handleLastElementIntersect);
        }
    }, [observer]);

    useEffect(() => {
        handlerList.current = trackedHandlers;
    }, [trackedHandlers]);

    useEffect(() => {
        if (localState.atEnd) {
            atEnd?.call(null);
        }
    }, [localState.atEnd]);

    useEffect(() => {
        if (localState.isError) {
            onError?.call(null);
        }
    }, [localState.isError]);

    useEffect(() => {
        if (localState.pageManager.length > 0 && filterList(localState.pageManager).length === 0) {
            if (localState.pageManager.pagesLeftToLoad > 0) {
                dispatchLocalState({ type: PagedListStateActionNames.SET_NEEDS_PAGE });
            } else {
                setShowEmptyFilterResults(true);
            }

            return;
        }

        setShowEmptyFilterResults(false);

        if (localState.isEmpty || localState.isError) {
            return;
        }

        if (handlerList.current[LAST_ELEMENT_TRACKING_ID]) {
            handlerList.current[LAST_ELEMENT_TRACKING_ID].observe();
        }
    }, [localState.pageManagerHash]);

    useEffect(() => {
        if (!filterList) {
            return;
        }

        setShowEmptyFilterResults(localState.pageManager.length > 0 && filterList(localState.pageManager).length === 0);
    }, [filterList]);

    useEffect(() => {
        if (localState.needsPage) {
            getMoreData();
            return;
        }

        if (localState.pageManager.length === 0 && !localState.isEmpty) {
            dispatchLocalState({ type: PagedListStateActionNames.SET_IS_EMPTY });
        }
    }, [localState.needsPage]);

    useUpdateEffect(() => {
        resetHandlers();
        if (setCurrentPage) {
            setCurrentPage(localState.pageManager.startingPage);
        }

        // This is not ideal, but hopefully we can fix the way we observe
        // page transitions to do this better.
        localState.pageManager.clearAllPages();

        if (!localState.pageManager.getPage(localState.pageManager.startingPage)) {
            dispatchLocalState({ type: PagedListStateActionNames.SET_NEEDS_PAGE });
        }
    }, [localState.firstPageNumber]);

    const getMoreData = async () => {
        if (localState.atEnd || localState.isLoading) {
            if (localState.atEnd && localState.needsPage) {
                dispatchLocalState({ type: PagedListStateActionNames.UNSET_NEEDS_PAGE });
            }

            return;
        }

        dispatchLocalState({ type: PagedListStateActionNames.SET_LOADING });

        try {
            await getNextPage();

            if (localState.isFirstPage) {
                onFirstPage?.call(null, null);
            }
        } catch (e) {
            let shouldToast = true;
            console.error('Get next page error', e);

            if (localState.isFirstPage && onFirstPage) {
                shouldToast = onFirstPage(e);
            }

            if (shouldToast) {
                toast.error('There was an error loading your items');
            }

            await dispatchLocalState({
                type: PagedListStateActionNames.SET_IS_ERROR,
            });
        }
    };

    const renderData = () => {
        if (localState.pageManager.length > 0 && filterList(localState.pageManager).length === 0) {
            if (!showEmptyFilterResults) {
                setShowEmptyFilterResults(true);
            }

            return null;
        }

        return filterList(localState.pageManager).map((dataItem, _index: number) => {
            const id = idSeq();

            if (Array.isArray(dataItem.row)) {
                return null;
            }

            return (
                <ListItemComponent
                    key={id}
                    id={id}
                    data={dataItem.row}
                    shouldShowLogo={shouldShowLogo}
                    dense={dense}
                    pageNumber={dataItem.pageNumber}
                    pageIndex={dataItem.pageIndex}
                />
            );
        });
    };

    const renderLoadingTop = () => {
        if (localState.pageManager.length === 0 && localState.isLoading) {
            return (
                <ul className="paged-list__loading">
                    {new Array(pageLength).fill('').map(() => {
                        return (
                            <li key={loadingKeys()}>
                                {CustomLoaderComponent ? (
                                    <CustomLoaderComponent />
                                ) : (
                                    <Skeleton containerTestId="paged-list-skeleton" />
                                )}
                            </li>
                        );
                    })}
                </ul>
            );
        }

        return null;
    };

    const renderLoadingBottom = () => {
        if (localState.pageManager.length === 0 || !localState.isLoading) {
            return null;
        }

        if (CustomLoaderComponent) {
            return <CustomLoaderComponent />;
        }

        return (
            <div className={skeletonClassnames}>
                <Skeleton containerTestId="paged-list-skeleton" />
            </div>
        );
    };

    const renderErrorComponent = () => {
        if (!localState.isError) {
            return null;
        }

        if (ErrorComponent) {
            return <ErrorComponent />;
        }
    };

    if (localState.isEmpty) {
        if (EmptyListComponent) {
            return <EmptyListComponent />;
        } else {
            return null;
        }
    }

    const handleClearFilterClick = () => {
        setShowEmptyFilterResults(false);

        if (onEmptyFilteredList?.call(null)) {
            return;
        }

        dispatchLocalState({ type: PagedListStateActionNames.SET_IS_EMPTY });
    };

    if (showEmptyFilterResults) {
        return (
            <div className="paged-list">
                <div className="paged-list__empty-filtered-list" data-testid="empty-filtered-list">
                    <h2>No results out of {localState.pageManager.length} match your filter</h2>
                    <Button
                        text="Show unfiltered list"
                        size={ButtonSize.MEDIUM}
                        color={ButtonColor.PRIMARY}
                        onClick={handleClearFilterClick}
                        testId="empty-filtered-list-clear-filter"
                    />
                </div>
            </div>
        );
    }

    return (
        <PagedListWrapper>
            {pageSelectorComponent}
            <div className="paged-list">
                {renderLoadingTop()}
                <ul className={listClassnames} ref={topRef} data-testid="paged-list-list">
                    {renderData()}
                </ul>
                {renderLoadingBottom()}
                {renderErrorComponent()}
            </div>
            {setCurrentPage && (
                <PagingComponent
                    firstPage={localState.pageManager.startingPage}
                    localState={localState}
                    dispatchLocalState={dispatchLocalState}
                />
            )}
        </PagedListWrapper>
    );
}
