export const lsSetItemWithExpiry = (key: string, value: string, ttl: number) => {
    const now = new Date();

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
};

export const lsGetItemWithExpiry = (key: string) => {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (!item?.expiry || now.getTime() > item?.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
};
