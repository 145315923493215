import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AppDispatch } from '../../store/widget';
import { setActiveOffer, setCheckoutPanelOpen } from '../../store/slices/checkout-slice';
import { getOffersDetailsData, PrizeoutOffer } from '../../store/slices/offers-slice';

export function useSelectOffer() {
    const dispatch = useDispatch<AppDispatch>();

    const setOfferAndOpenCheckout = (offer: PrizeoutOffer) => {
        dispatch(setActiveOffer(offer));
        dispatch(setCheckoutPanelOpen());
    };

    const selectOfferAndOpenCheckout = async (offer: PrizeoutOffer, shouldLoadOfferDetails: boolean = false) => {
        if (shouldLoadOfferDetails) {
            try {
                const offerDetails = await dispatch(
                    getOffersDetailsData({
                        offerId: offer.offer_id,
                    }),
                ).unwrap();
                setOfferAndOpenCheckout(offerDetails);
            } catch (e) {
                toast.error('Failed to load offer data. Please try again.');
            }
        } else {
            setOfferAndOpenCheckout(offer);
        }
    };

    return {
        selectOfferAndOpenCheckout,
    };
}
