import React from 'react';
import { BackArrowButton, ExitButton } from '..';
import { SidebarToggle } from '../../features/sidebar/common';
import {
    PrizeoutProductVersion,
    selectHideExitButton,
    selectIsSingleWithdrawal,
    selectPrizeoutProductVersion,
} from '../../../store/slices/partner-config-slice';
import { CheckoutViewEnum, selectCheckoutView } from '../../../store/slices/checkout-slice';
import { selectIsMobileLandscape } from '../../../store/slices/common-slice';
import { useAppSelector, useGoToRoute } from '../../../hooks';
import { HeaderLayout } from './common/header-layout';
import { UserBalanceAccountSelector } from '../ui-widgets';
import { selectIsGiftingMode } from '../../../store/slices/sdk-slice';
import { routes } from '../../../utils/helpers/routing-helpers';
import { HeaderEndItems } from './common/header-end-items';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';
import { LocationSelector } from '../../features/location-selector/location-selector';

export const Header: React.FC = (): React.ReactElement => {
    const hideExit = useAppSelector(selectHideExitButton);
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);
    const isSingleWithdrawal = useAppSelector(selectIsSingleWithdrawal);
    const activeView = useAppSelector(selectCheckoutView);
    const isCheckoutConfirmationView =
        activeView == CheckoutViewEnum.CHECKOUT_CONFIRMATION ||
        activeView == CheckoutViewEnum.CHECKOUT_REWARDS_CONFIRMATION;
    const hideNav = isSingleWithdrawal && isCheckoutConfirmationView;
    const isGiftingMode = useAppSelector(selectIsGiftingMode);
    const prizeoutProductVersion = useAppSelector(selectPrizeoutProductVersion);
    const { goToRoute } = useGoToRoute();

    const shouldShowExitButton = !hideExit && !isGiftingMode;
    const shouldShowUserBalanceAccountSelector =
        !isMobileLandscape && prizeoutProductVersion == PrizeoutProductVersion.STANDARD;

    const determineStartItem = () => {
        if (isGiftingMode) {
            return (
                <BackArrowButton
                    onClick={() => goToRoute(routes.GIFTING)}
                    trackingType={ButtonTrackingTypes.GIFTING_HEADER_BACK_BUTTON_CLICK}
                />
            );
        }

        if (shouldShowExitButton) {
            return <ExitButton />;
        }

        return null;
    };

    const determineEndItem = () => {
        if (hideNav) {
            return null;
        }

        return (
            <HeaderEndItems>
                <LocationSelector />
                {shouldShowUserBalanceAccountSelector && <UserBalanceAccountSelector />}
                {!isGiftingMode && <SidebarToggle />}
            </HeaderEndItems>
        );
    };

    return <HeaderLayout startItem={determineStartItem()} endItem={determineEndItem()} />;
};
