import { useDispatch } from 'react-redux';
import { getOffersMetadata } from '../../store/slices/offers-slice';
import { AppDispatch } from '../../store/widget';
import { useAppSelector } from '../use-app-selector';
import { selectPrizeoutUserSession } from '../../store/slices/partner-config-slice';
import { getSdkParams } from '../../store/slices/sdk-slice';
import { useState } from 'react';

export function useLoadOfferMetadata() {
    const dispatch = useDispatch<AppDispatch>();
    const prizeoutSessionId = useAppSelector(selectPrizeoutUserSession);
    const sdkParams = useAppSelector(getSdkParams);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const loadOfferMetadata = async () => {
        try {
            await dispatch(
                getOffersMetadata({
                    prizeoutSessionId,
                    sessionId: sdkParams?.session_id,
                }),
            );
        } catch (e) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isError,
        isLoading,
        loadOfferMetadata,
    };
}
