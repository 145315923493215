import { PrizeoutOfferDenomType, PrizeoutOfferMini } from '../../store/slices/offers-slice';

export function useGetCategoryOffers() {
    const getCategoryOffers = (offersMetadata: PrizeoutOfferMini[], categoryId: number): PrizeoutOfferMini[] =>
        offersMetadata
            .filter((offer) => offer.product_categories.includes(Number(categoryId)))
            .map((offer) => ({
                ...offer,
                giftcard_list: [
                    {
                        checkout_value_id: offer.offer_id,
                        cost_in_cents: 0,
                        denom_type: PrizeoutOfferDenomType.SINGLE_DENOM,
                        display_bonus: offer.display_bonus,
                        display_prev_bonus: offer.display_bonus,
                        display_monetary_bonus: offer.display_monetary_bonus,
                        expires: false,
                        value_in_cents: 0,
                    },
                ],
            }));

    return {
        getCategoryOffers,
    };
}
