import React from 'react';
import Classnames from 'classnames';
import { ProductBrandingHeader, CobrandedImage, Logo } from '../../ui-widgets';
import { useAppSelector } from '../../../../hooks';
import { RoundImageSizes, selectIsCobranded } from '../../../../store/slices/common-slice';
import { PrizeoutProductVersion, selectPrizeoutProductVersion } from '../../../../store/slices/partner-config-slice';

import './header-layout.scss';

interface HeaderLayoutProps {
    customClassName?: string;
    endItem?: React.ReactNode;
    startItem?: React.ReactNode;
}

export const HeaderLayout: React.FC<HeaderLayoutProps> = ({
    customClassName,
    endItem,
    startItem,
}): React.ReactElement => {
    const isCobranded = useAppSelector(selectIsCobranded);
    const prizeoutProductVersion = useAppSelector(selectPrizeoutProductVersion);
    const shouldShowBranding = prizeoutProductVersion !== PrizeoutProductVersion.STANDARD;

    const classes = Classnames('header-layout z-index-header', customClassName);

    const determineStartItem = () => {
        if (!!startItem) {
            return startItem;
        }

        if (shouldShowBranding) {
            return <ProductBrandingHeader />;
        }

        return null;
    };

    const determineCenterItem = () => {
        if (shouldShowBranding) {
            if (!!startItem) {
                return <ProductBrandingHeader />;
            }

            return null;
        }

        if (isCobranded) {
            return (
                <div className="header-layout__cobranded">
                    <CobrandedImage testId="header-cobranded-image-prizeout" size={RoundImageSizes.TWO} />
                    <CobrandedImage testId="header-cobranded-image-partner" size={RoundImageSizes.TWO} isPartner />
                </div>
            );
        }

        return <Logo />;
    };

    return (
        <header data-testid="header-layout" className={classes}>
            <div className="header-layout__content">
                <div className="header-layout__item header-layout__item--start">{determineStartItem()}</div>

                <div className="header-layout__item">{determineCenterItem()}</div>

                {endItem && <div className="header-layout__item header-layout__item--end">{endItem}</div>}
            </div>
        </header>
    );
};
