import React, { useEffect, useState } from 'react';
import Classnames from 'classnames';
import { useAppSelector } from '../../../hooks';
import {
    selectCurrentSelectedOffer,
    selectCustomDenomDisplayAmountEarned,
    selectCustomDenomDisplayValue,
} from '../../../store/slices/checkout-slice';
import { convertCentsToDollars, currencyDisplay } from '../../../utils';
import { ClickableCard } from '../cards';
import { PrizeoutOffer } from '../../../store/slices/offers-slice';

import './custom-denom-button.scss';

interface CustomDenomButtonProps {
    bonus?: number;
    isActive?: boolean;
    monetaryBonus?: number;
    onClick: () => void;
}

export const CustomDenomButton: React.FC<CustomDenomButtonProps> = ({ isActive, onClick }): React.ReactElement => {
    const currentOffer: PrizeoutOffer = useAppSelector(selectCurrentSelectedOffer);
    const customDenomDisplayValue = useAppSelector(selectCustomDenomDisplayValue);
    const customDenomDisplayAmountEarned = useAppSelector(selectCustomDenomDisplayAmountEarned);
    const [buttonText, setButtonText] = useState('Enter amount');
    const [bonusValue, setBonusValue] = useState(null);

    const classes = Classnames('custom-denom-button', { 'custom-denom-button--active': isActive });

    const setDisplays = () => {
        setButtonText(
            currencyDisplay(
                currentOffer.currency_code,
                parseFloat(convertCentsToDollars(customDenomDisplayValue)),
                true,
            ),
        );

        setBonusValue(
            currencyDisplay(
                currentOffer.currency_code,
                parseFloat(convertCentsToDollars(customDenomDisplayAmountEarned)),
                true,
            ),
        );
    };

    useEffect(() => {
        if (customDenomDisplayValue) {
            setDisplays();
        }
    }, [customDenomDisplayValue]);

    return (
        <ClickableCard className={classes} onClick={onClick} testId={'custom-denom-button'}>
            <div className="custom-denom-button__text">
                <span>{buttonText}</span>
                {customDenomDisplayValue && <i className="fa-kit fa-prizeout-edit"></i>}
            </div>
            {bonusValue && (
                <span>
                    {bonusValue} {currentOffer.display_offer_type}
                </span>
            )}
        </ClickableCard>
    );
};
