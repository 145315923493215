import { QueryUtilsPagedQuery } from '../../../hooks/store';
import { PartnerCredentials } from '../../../modules/partner-portal/hooks/use-authentication-manager';
import { UserReward } from '../rewards';

export enum PortalEventGroups {
    DashboardUsersList = 'dashboard-users-list',
    PortalAccountInviteList = 'partner-account-invite-list',
    PortalAccountList = 'partner-account-list',
    PortalCashbackBalances = 'cashback-balances',
    PortalIntegrationsDetails = 'portal-integrations-details',
    PortalLogin = 'partner-login',
    PortalPartnerList = 'partner-partner-list',
    PortalRewardsStatistics = 'partner-rewards-statistics',
    PortalRewardsSummaryData = 'partner-rewards-summary-data',
    PortalRewardsUsersList = 'partner-rewards-users-list',
}

export interface PartnerPortalMutationResponse {
    success: boolean;
}

export interface PartnerRewardStatistics {
    numActivatedRewards: number;
    numCompletedRewards: number;
    cashbackAwardedCents: number;
    cashbackClaimedCents: number;
    currencyCode: string;
}

export interface PartnerRewardStatisticsArgs {
    partnerRewardId: string;
    query: PartnerRewardStatisticsQuery;
}

export interface PartnerRewardStatisticsQuery {
    startDate: string;
    endDate: string;
}

export interface PartnerInfo {
    accountId: string;
    accountName: string;
    name: string;
    email: string;
}

export interface UserRewardsQuery extends QueryUtilsPagedQuery {
    partnerUserId?: string;
    partnerRewardId?: string;
}

export interface UserRewards {
    reward: UserReward;
    user: RewardsUser;
}

export interface RewardsUsersQuery {
    partnerUserId?: string;
    prizeoutUserId?: string;
    orderBy?: string;
    currentPage: number;
    perPage: number;
}

export interface RewardsUser {
    prizeoutUserId: string;
    partnerId: string;
    partnerUserId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
}

export enum UserCashbackLiabilityOwnerTypes {
    PARTNER = 'partner',
    PRIZEOUT = 'prizeout',
}

export interface UserCashbackLiabilitySplit {
    walletId: string;
    liabilityOwner: UserCashbackLiabilityOwnerTypes;
    sum: number;
}

export interface UserCashbackSummary {
    userId: string;
    walletId: string;
    balance: number;
    currencyCode: string;
    currencyDecimals: number;
    totalLifetimeEarned: number;
    liabilitySplits: UserCashbackLiabilitySplit[];
}

export interface UserCashbackSummaryResponse {
    user: RewardsUser;
    cashback: UserCashbackSummary;
}

export interface UserCashbackWallet {
    userId: string;
    walletId: string;
    balance: number;
    currencyCode: string;
    currencyDecimals: number;
    totalLifetimeEarned: number;
}

export interface UserCashbackBalancesResponse {
    user: RewardsUser;
    wallet: UserCashbackWallet;
}

export interface UserCashbackBalancesArgs {
    currentPage: number;
    perPage: number;
}

export interface LoginPartnerRequest extends PartnerCredentials {
    sessionValidityMinutes?: number;
}

export interface ActivePartnerInfo {
    partnerId: string;
    partnerName: string;
}

export interface ActivePartnerAccount {
    partnerId: string;
}

export interface PasswordResetRequest {
    email: string;
}

export interface PasswordSetRequest {
    password: string;
    otaToken: string;
}

export enum DashboardUserPerm {
    ADMIN = 'admin',
    ENGINEERING = 'engineering',
    SUPPORT = 'support',
    OPERATIONS = 'operations',
    FINANCE = 'finance',
}

export interface DashboardUserInfo {
    dashboardUserId: string;
}

export interface NewSandboxAccountInfo {
    newPartnerId: string; // uuid
    sandboxEnvName: string;
}

export interface SandboxAccount {
    partnerId: string; // uuid
    sandboxEnvName: string;
    masterId: string;
}

export interface NewDashboardUserInfo {
    name: string;
    email: string;
    perms: DashboardUserPerm[];
}

export interface DashboardUserPermsInfo {
    dashboardUserId: string;
    perms: DashboardUserPerm[];
}

export interface PartnerAccount {
    accountListId: string;
    dashboardUserId: string;
    email: string;
    name: string;
    partnerId: string;
    env: string;
    accountType: string;
    accountName: string;
    isMaster: boolean;
    sandboxEnvName?: string;
    createDate: string;
    updateDate: string;
    masterId: string;
    perms: DashboardUserPerm[];
    accessed: boolean;
}

export interface PartnerAPIKeys {
    apiKey: string;
    apiKeySecondary: string;
    secretKey: string;
    secretKeySecondary: string;
    securityToken: string;
    securityTokenSecondary: string;
}

export enum PartnerIntegrationWithdrawalType {
    REVIEW = 'review',
    INSTANT = 'instant',
}

export interface PartnerIntegrationSettings {
    supportEmail: string;
    withdrawalType: PartnerIntegrationWithdrawalType;
}

export enum PartnerIntegrationCallbackName {
    BALANCE = 'balance',
    ON_CREDIT = 'onCredit',
    ON_DELIVERY = 'onDelivery',
    ON_DELIVERY_FAILURE = 'onDeliveryFailure',
    ON_FAIL = 'onFail',
    ON_FI_BANK_ACCOUNT_FETCH = 'onFiBankAccountFetch',
    ON_FI_REWARD_CREDIT = 'onFiRewardCredit',
    ON_FI_REWARD_DEPOSIT = 'onFiRewardDeposit',
    ON_FUNDS_CREDIT = 'onFundsCredit',
    ON_FUNDS_DEBIT = 'onFundsDebit',
    ON_FUNDS_DEPOSIT = 'onFundsDeposit',
    ON_OPEN = 'onOpen',
    ON_REJECT = 'onReject',
    ON_REQUEST = 'onRequest',
    ON_SESSION = 'onSession',
    ON_SUCCESS = 'onSuccess',
}

export interface PartnerIntegrationCallback {
    name: PartnerIntegrationCallbackName;
    value: string;
}

export interface PartnerIntegrationDetails {
    partnerId: string;
    apiKeys: PartnerAPIKeys;
    settings: PartnerIntegrationSettings;
    callbacks: PartnerIntegrationCallback[];
}
